.ag-object-list .ag-header-cell-label {
  text-overflow: unset;
  white-space: nowrap;
  overflow-x: auto;
  padding: 8px 16px;
  height: 40px;
  line-height: 40px; /* Ensures vertical centering */
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.ag-object-list .ag-even-row {
  background-color: #f9f9f9;
}

.ag-object-list .ag-odd-row {
  background-color: #ffffff;
}
