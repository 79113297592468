.ag-theme-inbound-call-flow-list {
  --ag-odd-row-background-color: #f7f9ff !important;
  --ag-header-background-color: #f7f9ff !important;
  --ag-header-height: 40px !important;
  --ag-row-height: 40px !important;
  --ag-font-size: 12px !important;
  --ag-font-family: "Inter", sans-serif !important;
  --ag-borders: none !important;
  --ag-range-selection-border-color: #00000000 !important;
}

.ag-row-drag {
  visibility: hidden !important;
  display: none !important;
}

.ag-inbound-call-flow-header {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  /* Neutral/Neutral-Main */
  background-color: white !important;
  color: #949ab1 !important;
}

.ag-inbound-call-flow-header div {
  justify-content: left !important;
}

.ag-theme-inbound-call-flow-list .ag-row-even {
  background-color: #f7f9ff !important;
}

.ag-theme-inbound-call-flow-list .ag-row-odd {
  background-color: #ffffff !important;
}
