.ag-theme-individual-sale {
  --ag-odd-row-background-color: #f7f9ff;
  --ag-header-background-color: #ffffff;
  --ag-header-height: 40px;
  --ag-row-height: 40px;
  --ag-font-size: 12px;
  --ag-font-family: "Inter", sans-serif;
  --ag-borders: none;
  --ag-range-selection-border-color: #00000000;

  height: 445px;
  min-height: max-content;
}

.ag-theme-individual-sale .ag-header-cell-text {
  color: #a6aabc;
  font-weight: 600;
  font-size: 12px;
}

.ag-theme-individual-sale .ag-cell,
.ag-theme-individual-sale .ag-header-cell {
  padding-left: 16px;
}

.ag-theme-individual-sale .ag-salesteam-header {
  font-weight: 600;
  justify-content: left;
}

.ag-theme-individual-sale .ag-root-wrapper.ag-ltr.ag-layout-normal {
  height: 100% !important;
}
