.ag-theme-object-manager {
  --ag-odd-row-background-color: #f7f9ff !important;
  --ag-even-row-background-color: #fff !important;
  --ag-header-background-color: #ecf1ff !important;
  --ag-header-height: 40px !important;
  --ag-row-height: 40px !important;
  --ag-font-size: 12px !important;
  --ag-font-family: "Inter", sans-serif !important;
  --ag-borders: none !important;
  --ag-range-selection-border-color: #00000000 !important;
  --ag-odd-row-height: 40px !important;
  --ag-even-row-height: 40px !important;
}

.ag-object-manager-header {
  font-size: 12px !important;
  font-weight: 600 !important;
  height: 40px !important;
}

.ag-object-manager-header-center .ag-header-cell-label {
  justify-content: center !important;
  text-align: center !important;
}
