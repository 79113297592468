.ag-theme-lead-search-list {
  --ag-header-background-color: #ecf1ff !important;
  --ag-header-height: 40px !important;
  --ag-row-height: 40px !important;
  --ag-font-size: 12px !important;
  --ag-font-family: "Inter", sans-serif !important;
  --ag-borders: none !important;
  --ag-range-selection-border-color: #00000000 !important;
}

.ag-theme-lead-search-list .ag-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

.ag-lead-search-header {
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  gap: 8px !important;
  height: 40px !important;
  padding: 0px 15px !important;
  border-right: 1px solid #d8e5ff;
}

.ag-lead-search-cell {
  border-right: 1px solid #d8e5ff !important;
  padding: 0px 15px !important;
}

.ag-lead-search-cell-checkbox {
  border-right: 1px solid #d8e5ff !important;
  padding: 0px 15px !important;
  display: flex !important;
  align-items: center !important;
}

.ag-theme-lead-search-list .ag-row-odd {
  background-color: #f7f9ff !important;
}

.ag-theme-lead-search-list .ag-row-even {
  background-color: #ffffff !important;
}

.ag-theme-lead-search-list .ag-header-group-cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.ag-theme-lead-search-list .ag-header-cell,
.ag-theme-lead-search-list .ag-header-group-cell {
  padding: 0px !important;
}
