.ag-theme-integration-mapping-table {
  --ag-odd-row-background-color: #f7f9ff;
  --ag-header-background-color: #f7f9ff;
  --ag-header-height: 40px;
  --ag-row-height: 40px;
  --ag-font-size: 12px;
  --ag-font-family: "Inter", sans-serif;
  --ag-borders: none;
  --ag-range-selection-border-color: #00000000;
}

.ag-theme-leads-table {
  --ag-cell-horizontal-border: solid #00000040;
  --ag-odd-row-background-color: #ffffff;
  --ag-header-background-color: #ecf1ff;
  --ag-header-height: 40px;
  --ag-row-height: 40px;
  --ag-font-size: 10px;
  --ag-font-family: "Inter", sans-serif;
  --ag-header-column-separator-display: block;
  --ag-header-column-resize-handle-display: none;

  .ag-cell-wrapper {
    width: 100%;
  }
}

.ag-row-drag {
  visibility: hidden;
  display: none !important;
}

.ag-theme-integration-mapping-table-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  background-color: #ecf1ff;
  color: #000000;
}

.ag-theme-integration-mapping-table-header-center {
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    justify-content: center;
  }
  .ag-cell-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.ag-integration-mapping-table-header div {
  justify-content: left;
}

.ag-theme-integration-mapping-table .ag-row-even {
  font-size: 12px;
  font-weight: 400;
}
