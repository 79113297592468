.ag-theme-salesteam-list {
  --ag-odd-row-background-color: #f7f9ff;
  --ag-header-background-color: #f7f9ff;
  --ag-header-height: 40px;
  --ag-row-height: 40px;
  --ag-font-size: 12px;
  --ag-font-family: "Inter", sans-serif;
  --ag-borders: none;
  --ag-range-selection-border-color: #00000000;
}

.ag-salesteam-header {
  font-weight: 600;
  /* cursor: grab; */
}
.ag-salesteam-header div {
  justify-content: left;
}
.ag-theme-salesteam-list .ag-center-cols-container {
  /* always extend rows to edge of table */
  /* width: 100% !important; */
}
