.ag-theme-call-library-list {
  --ag-header-height: 40px !important;
  --ag-row-height: 40px !important;
  --ag-font-size: 12px !important;
  --ag-font-family: "Inter", sans-serif !important;
  --ag-borders: none !important;
}

.ag-row-drag {
  visibility: hidden !important;
  display: none !important;
}

.ag-call-library-header {
  font-weight: 600 !important;
  font-size: 12px !important;
  background: #f7f9ff !important;
}

.ag-call-library-header-center {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #949ab1 !important;
  background: #f7f9ff !important;
  justify-content: center !important;
}

.ag-call-library-header-center div {
  justify-content: center !important;
}

.ag-call-library-header div {
  justify-content: left !important;
}

.ag-theme-call-library-list .ag-row-odd {
  background-color: #f7f9ff !important;
}

.ag-theme-call-library-list .ag-row-even {
  background-color: #ffffff !important;
}

.ag-theme-call-library-list .ag-cell {
  border: none !important;
}
