.ag-theme-lead-ranking-table {
  --ag-odd-row-background-color: #fafcff !important;
  --ag-even-row-background-color: #ffffff !important;
  --ag-header-background-color: #ecf1ff !important;
  --ag-header-height: 40px !important;
  --ag-row-height: 40px !important;
  --ag-font-size: 12px !important;
  --ag-font-family: "Inter", sans-serif !important;
  --ag-borders: 1px solid black !important;
  --ag-range-selection-border-color: #00000000 !important;
  border-radius: 6px !important;
  border: 1px solid #a6aabc !important;
  overflow: hidden !important;
}

.ag-lead-ranking-table-header .div {
  border-radius: 6px !important;
  border: 1px solid black !important;
  overflow: hidden !important;
}

.ag-theme-lead-ranking-table .ag-row-drag {
  visibility: hidden !important;
  display: none !important;
}

.ag-lead-ranking-table-header {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  line-height: 18px !important;
  background-color: #ecf1ff !important;
  color: black !important;
}

.ag-lead-ranking-table-header div {
  justify-content: left !important;
}
